.App-logo {
  height: 7rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.Header {
  display: flex;
  justify-content: center;
}

.text-description {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.rs-footer {
  background-color: #efefef;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}